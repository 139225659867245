import React, { useState, useEffect, useContext, useRef } from "react";
import Navbar from "../components/Navbar";
import MyButton from "../components/UI/button/MyButton";
import { API_AUTH_URL, ORGANIZATION_NAME } from "../config";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import MyLoader from "../components/UI/loader/MyLoader";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import DeleteEventConfirm from "../components/DeleteEventConfirm";
import { getEventsDate } from "../utils/createEventsDate";

const ConfirmPage = () => {
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [userEvents, setUserEvents] = useState([]);

  const {
    authTokens,
    loading,
    setLoading,
    setGeneralError,
    updateToken,
    generalError,
    closeAlert,
    isRefreshingToken,
    setIsRefreshingToken,
  } = useContext(AuthContext);

  useEffect(() => {
    document.title = "Cогласование | " + ORGANIZATION_NAME;
  }, []);

    const getUserEventsData = async () => {
        setLoading(true);
        try {
            const res = await axios({
                url: `${API_AUTH_URL}/api/v1/events/`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "ru",
                    Authorization: `Bearer ${authTokens.access_token}`,
                },
                params: {
                    start: getEventsDate()[0],
                    end: getEventsDate()[1],
                    for_approval: true,
                },
            });
            setUserEvents(res.data);
        } catch (e) {
            console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                await getUserEventsData();
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data.code) {
                setGeneralError([e.response.status, e.response.data.code]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
            setLoading(false);
        }
    };

  useEffect(() => {
    getUserEventsData();
    const body = document.getElementsByClassName("App")[0];
    body.classList.remove("lock");
  }, []);

  useEffect(() => {
    setFilteredEvents(userEvents); // дописать фильтр
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [userEvents]);

  return (
    <div>
      <Navbar />
      <div className="main">
        {loading ? (
          <MyLoader />
        ) : (
          <div className="list-events-block list-events-block-confirming">
            <div className="page-name-block form-item-title">
              Согласование мероприятий
            </div>
            {filteredEvents.length > 0 ? (
              <div className="events-list-block" key={filteredEvents}>
                {filteredEvents.map((event) => (
                  <div className="one-event-block" key={event.id}>
                    <Link
                      to={"/confirm/" + event.id}
                      className={"event-link"}
                      key={event.id}
                      onClick={() => setLoading(true)}
                    >
                      <div className="one-event-info">
                        <div className="one-event-name">{event.name}</div>
                        <div className="one-event-date">
                          {dayjs(event.date_start).format("DD.MM.YYYY") ===
                          dayjs(event.date_end).format("DD.MM.YYYY")
                            ? dayjs(event.date_start).format("DD.MM.YYYY")
                            : dayjs(event.date_start).format("DD.MM.YYYY") +
                              " - " +
                              dayjs(event.date_end).format("DD.MM.YYYY")}
                          {" | "}
                          {dayjs(event.date_start).format("HH:mm")} -{" "}
                          {dayjs(event.date_end).format("HH:mm")}
                        </div>
                        <div className="one-event-type">{event.type.name}</div>
                        <div className="one-event-classes">
                          {event.klasses && (
                            <div className="detailed-audience">
                              {event.klasses.map((item) => (
                                <div
                                  className="detailed-audience-item"
                                  key={item.id}
                                >
                                  {item.parallel + item.letter}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-events-block">Здесь пока нет мероприятий</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmPage;
